<template>
  <div class="auth--recover">
    <header>
      <h1 class="text-h mt-6">Recuperar Contraseña</h1>
      <p class="text--secondary">Indicanos tu email de la cuenta.</p>
    </header>

    <!-- Form -->
    <v-form :disabled="loading" ref="form" @submit.prevent class="my-9">
      <div class="form-group mb-2">
        <label>Email:</label>
        <v-text-field flat solo-inverted :rules="emailR" v-model="email" />
      </div>
      <v-btn
        block
        large
        :disabled="loading"
        :loading="loading"
        depressed
        color="primary"
        @click="onSubmit"
      >
        Recuperar
      </v-btn>

      <v-btn block text class="mt-4" to="/">Volver al Login</v-btn>
    </v-form>
  </div>
</template>

<script>
import { auth } from "@/firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import Rules from "@/mixins/rules";

export default {
  data: () => ({
    email: "",
    loading: false,
  }),

  mixins: [Rules],

  methods: {
    async onSubmit() {
      if (this.$refs.form.validate()) {
        sendPasswordResetEmail(auth, this.email)
          .then(() => {
            console.debug("Password reset email sent!: ", this.email);
            this.$refs.form.reset()
          })
          .catch((error) => {
            console.debug(error.message);
          });
      }
    },
  },
};
</script>